



































import Vue from "vue";
import Footer from "@/components/back-footer.vue";
export default Vue.extend({
  data: function () {
    return {
      values: [
        {
          value: "Abundance",
          selected: false,
          grouped: true,
          groupClass: "yellow",
          group: 1,
          isPrimary: false,
        },
        {
          value: "Ambition",
          selected: false,
          grouped: true,
          groupClass: "pale",
          group: 2,
          isPrimary: false,
        },
        {
          value: "Benevolence",
          selected: false,
          grouped: true,
          groupClass: "pale",
          group: 2,
          isPrimary: true,
        },
        {
          value: "Compassion",
          selected: false,
          grouped: true,
          groupClass: "green",
          group: 3,
          isPrimary: false,
        },
        {
          value: "Curiosity",
          selected: false,
          grouped: true,
          groupClass: "blue",
          group: 4,
          isPrimary: false,
        },
        {
          value: "Empathy",
          selected: false,
          grouped: true,
          groupClass: "yellow",
          group: 1,
          isPrimary: true,
        },
        {
          value: "Fairness",
          selected: false,
          grouped: true,
          groupClass: "red",
          group: 5,
          isPrimary: false,
        },
        {
          value: "Generosity",
          selected: false,
          grouped: true,
          groupClass: "red",
          group: 5,
          isPrimary: false,
        },
        {
          value: "Humility",
          selected: false,
          grouped: true,
          groupClass: "yellow",
          group: 1,
          isPrimary: false,
        },
        {
          value: "Acceptance",
          selected: false,
          grouped: true,
          groupClass: "yellow",
          group: 1,
          isPrimary: false,
        },
        {
          value: "Appreciation",
          selected: false,
          grouped: true,
          groupClass: "yellow",
          group: 1,
          isPrimary: false,
        },
        {
          value: "Boldness",
          selected: false,
          grouped: true,
          groupClass: "yellow",
          group: 1,
          isPrimary: false,
        },
        {
          value: "Cheerfulness",
          selected: false,
          grouped: true,
          groupClass: "green",
          group: 3,
          isPrimary: false,
        },
        {
          value: "Quality",
          selected: false,
          grouped: true,
          groupClass: "yellow",
          group: 1,
          isPrimary: false,
        },
        {
          value: "Responsibility",
          selected: false,
          grouped: true,
          groupClass: "yellow",
          group: 1,
          isPrimary: false,
        },
        {
          value: "Selflessness",
          selected: false,
          grouped: true,
          groupClass: "pale",
          group: 2,
          isPrimary: false,
        },
        {
          value: "Teamwork",
          selected: false,
          grouped: true,
          groupClass: "yellow",
          group: 1,
          isPrimary: false,
        },
        {
          value: "Uniqueness",
          selected: false,
          grouped: true,
          groupClass: "red",
          group: 5,
          isPrimary: false,
        },
        {
          value: "Inspiration",
          selected: false,
          grouped: true,
          groupClass: "green",
          group: 3,
          isPrimary: false,
        },
        {
          value: "Leadership",
          selected: false,
          grouped: true,
          groupClass: "blue",
          group: 4,
          isPrimary: false,
        },
      ],
      groups: this.$store.state.values.groups,
    };
  },
  components: {
    Footer,
  },
  computed: {},

  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
  },
});
